var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "v-snackbar",
    {
      attrs: {
        absolute: _vm.snackbarPos === "absolute",
        bottom: _vm.y === "bottom",
        top: _vm.y === "top",
        right: _vm.x === "right",
        left: _vm.x === "left",
        color: _vm.computedColor,
        "multi-line": _vm.mode === "multi-line",
        timeout: _vm.computedTimeout,
        vertical: _vm.mode === "vertical"
      },
      model: {
        value: _vm.computedGlobalSnackbar.show,
        callback: function($$v) {
          _vm.$set(_vm.computedGlobalSnackbar, "show", $$v)
        },
        expression: "computedGlobalSnackbar.show"
      }
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm._f("truncate")(_vm.computedGlobalSnackbar.message, 400)) +
          "\n  "
      ),
      _c(
        "v-btn",
        { attrs: { dark: "", text: "" }, on: { click: _vm.closeSnackbar } },
        [_vm._v("\n    Dismiss\n  ")]
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }