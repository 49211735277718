<template>
  <!-- :style="{ position: snackbarPos }" -->
  <v-snackbar
    v-model="computedGlobalSnackbar.show"
    :absolute="snackbarPos === 'absolute'"
    :bottom="y === 'bottom'"
    :top="y === 'top'"
    :right="x === 'right'"
    :left="x === 'left'"
    :color="computedColor"
    :multi-line="mode === 'multi-line'"
    :timeout="computedTimeout"
    :vertical="mode === 'vertical'"
  >
    {{ computedGlobalSnackbar.message | truncate(400) }}
    <v-btn
      dark
      text
      @click="closeSnackbar"
    >
      Dismiss
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapGetters, mapMutations } from 'vuex'

export default {
  data () {
    return {
      snackbarPos: 'fixed',
      x: 'right', // hardcoded for now (styling restrictions)
      y: 'bottom',
      mode: 'multi-line',
      timeout: 5000,
      color: 'blue'
    }
  },

  computed: {
    ...mapGetters(['globalSnackbar', 'showGlobalSnackbar']),
    computedGlobalSnackbar: {
      get: function () {
        return this.globalSnackbar()
      },
      set: function (value) {
        this.setGlobalSnackbar(value)
      }
    },
    computedTimeout () {
      if (
        this.computedGlobalSnackbar.color === 'red' ||
        this.computedGlobalSnackbar.color === 'error'
      ) {
        return 0
      }
      return this.computedGlobalSnackbar.timeout || this.timeout
    },
    computedColor () {
      if (this.computedGlobalSnackbar.color) {
        return this.computedGlobalSnackbar.color
      } else {
        return this.color
      }
    }
  },

  watch: {
    computedGlobalSnackbar (newValue) {
      if (newValue.show && newValue.timeout > 0) {
        this.timeout = setTimeout(() => {
          this.computedGlobalSnackbar = null
        }, newValue.timeout)
      } else {
        this.timeout = null
      }
    }
  },

  mounted () {
    window.addEventListener('scroll', this.getPosition)
    this.getPosition()
  },

  beforeDestroy () {
    // TODO: review this interaction
    // if (this.computedGlobalSnackbar.show) {
    //   this.computedGlobalSnackbar = null;
    // }
    window.removeEventListener('scroll', this.getPosition)
  },

  methods: {
    ...mapMutations(['setGlobalSnackbar']),
    getPosition (event) {
      if (window.innerHeight + window.scrollY >= document.body.offsetHeight) {
        this.snackbarPos = 'absolute'
      } else {
        this.snackbarPos = 'fixed'
      }
    },
    closeSnackbar () {
      // this.computedGlobalSnackbar.show = false
      // this.$nextTick(() => {
      this.computedGlobalSnackbar = null
      // })
    }
  }
}
</script>
